import {formatStringDateFromStringOrElse} from "../../../utils/dates"

type Timeline = {
    actual_start_date: string | null
    planned_start_date: string | null
    actual_end_date: string | null
    planned_end_date: string | null
}

const determineStartDate = (timeline: Timeline) => {
    if (timeline.actual_start_date) {
        return <span>{`${formatStringDateFromStringOrElse(timeline.actual_start_date, "?")}`}</span>
    }
    if (timeline.planned_start_date) {
        return <span>{`${formatStringDateFromStringOrElse(timeline.planned_start_date, "?")}`}</span>
    }
    return <span>?</span>
}

const determineEndDate = (project: Timeline) => {
    if (project.actual_end_date) {
        return <span>{`${formatStringDateFromStringOrElse(project.actual_end_date, "?")}`}</span>
    }
    if (project.planned_end_date) {
        return <span>{`${formatStringDateFromStringOrElse(project.planned_end_date, "?")}`}</span>
    }
    return <span>?</span>
}

export const determineDuration = (project: Timeline) => {
    return (
        <>
            {determineStartDate(project)} - {determineEndDate(project)}
        </>
    )
}
