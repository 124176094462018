import React from "react"
import SelectDropdown from "../../../components/SelectDropdown"
import StatusReportDatePicker from "./components/StatusReportDatePicker"
import {useTranslation} from "react-i18next"
import {addDays, compareDesc, max} from "date-fns"
import {gql, useMutation, useQuery} from "@apollo/client"
import {SelectedReport} from "./StatusReportViewer"

import {GetReportFilter, GetReportFilterVariables} from "./types/GetReportFilter"
import {UpdateUnreleasedReportDate, UpdateUnreleasedReportDateVariables} from "./types/UpdateUnreleasedReportDate"
import {formatDate, formatIsoDate} from "../../../utils/dates"
import {GetAllReports, GetAllReportsVariables} from "./types/GetAllReports"
import Grid from "@mui/material/Grid"
import { getReport } from "./StatusReport";
import {GetAllInitiativesForStatusReport} from "./types/GetAllInitiativesForStatusReport"

const getAllInitiativesForStatusReport = gql`
    query GetAllInitiativesForStatusReport {
        initiatives: jira_rm_initiative(where: {
            status_reports: {permissions: {_or: [
                {manager: {_eq: true}},
                {sponsor: {_eq: true}},
                {statusbericht_teilberechtigte: {_eq: true}},
                {statusbericht_vollberechtigte: {_eq: true}}
            ]}}
        }) {
            issue_id
            summary
        }
    }
`

const getAllReports = gql`
    query GetAllReports($initiativeIssueId: String) {
        status_report_status_report(where: {initiative_issue_id: {_eq: $initiativeIssueId}}) {
            id
            state
            report_date
            initiative_issue_id
        }
    }
`

const getReportFilter = gql`
    query GetReportFilter($reportId: uuid!) {
        report: status_report_status_report_by_pk(id: $reportId) {
            id
            initiative_issue_id
        }
    }
`

const updateUnreleasedReportDate = gql`
    mutation UpdateUnreleasedReportDate($report_id: uuid!, $report_date: date!) {
        update_status_report_status_report_by_pk(pk_columns: {id: $report_id}, _set: {report_date: $report_date}) {
            id
            report_date
        }
    }
`

interface Props {
    selectedReport: SelectedReport
    setSelectedReport: (report: SelectedReport) => void
}

const StatusReportFilters: React.FC<Props> = ({selectedReport, setSelectedReport}) => {
    const {t, i18n} = useTranslation("translations")

    const [selectedInitiative, setSelectedInitiative] = React.useState<string>("")

    const {data, error} = useQuery<GetAllInitiativesForStatusReport>(getAllInitiativesForStatusReport)

    const {data: allReports, error: reportsError} = useQuery<GetAllReports, GetAllReportsVariables>(getAllReports, {
        variables: {initiativeIssueId: selectedInitiative},
        skip: !selectedInitiative,
    })

    const {
        data: reportFilter,
        error: reportFilterError
    } = useQuery<GetReportFilter, GetReportFilterVariables>(getReportFilter, {
        variables: {reportId: selectedReport.reportId},
        skip: selectedReport.reportId === "" || selectedInitiative !== "",
    })

    const [updateReportDate] = useMutation<UpdateUnreleasedReportDate, UpdateUnreleasedReportDateVariables>(updateUnreleasedReportDate)

    React.useEffect(() => {
        if (reportFilter) {
            setSelectedInitiative(reportFilter.report?.initiative_issue_id ?? "")
        }
    }, [reportFilter])

    if (error) throw Error(error.message)
    if (reportsError) throw Error(reportsError.message)
    if (reportFilterError) throw Error(reportFilterError.message)

    const reports = () => allReports?.status_report_status_report ?? []
    const report = (reportId: string) => reports().find((report) => report.id === reportId)

    const handleProjectChange = (projectId: string) => {
        setSelectedInitiative(projectId)
        setSelectedReport({reportId: ""})
    }

    const handleReportChange = (reportId: string) => {
        setSelectedReport({...setSelectedReport, reportId: reportId})
    }

    const handleDateChange = async (date: Date | null) => {
        if (!date) return
        await updateReportDate({
            variables: {
                report_id: selectedReport.reportId,
                report_date: formatIsoDate(date),
            },
            refetchQueries: [getReport],
            awaitRefetchQueries: true,
        })
    }

    const reportsDropdown = (): { name: string; id: string }[] => {
        return (
            reports()
                .slice()
                .sort((left, right) => compareDesc(new Date(left.report_date), new Date(right.report_date)))
                .map((report) => {
                    if (report.state === "unreleased") {
                        return {name: `${t("unreleased")}`, id: report.id}
                    }
                    return {
                        name: `${formatDate(new Date(report.report_date!))} - ${t(report.state)}`,
                        id: report.id,
                    }
                }) ?? []
        )
    }

    const reportDate = (reportId: string): Date => {
        const report = reports().find((report) => report.id === reportId)
        if (!report) return new Date()
        return new Date(report.report_date)
    }

    const minDate = () => {
        const dates = reports()
            .filter((report) => report.state !== "canceled")
            .filter((report) => report.state !== "unreleased")
            .map((report) => new Date(report.report_date))

        if (dates.length === 0) return null
        return addDays(max(dates), 1)
    }

    const generateOptions = () => {
        const options = data?.initiatives
                .map((initiative) => ({name: initiative.summary!, id: initiative.issue_id!})) ?? []
        return options.sort((left, right) => left.name.localeCompare(right.name, i18n.language, {sensitivity: 'base'}))
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item>
                    <SelectDropdown
                        key="SelectStatusReportProject"
                        title={t("name")}
                        options={generateOptions()}
                        icon={"project-diagram"}
                        type={"projectId"}
                        handleChange={(event) => handleProjectChange(event.target.value)}
                        value={data ? selectedInitiative : ""}
                    />
                </Grid>
                <Grid item>
                    <SelectDropdown
                        key="SelectStatusReportVersion"
                        title={t("version")}
                        options={reportsDropdown()}
                        icon={"calendar-alt"}
                        type={"version"}
                        handleChange={(event) => handleReportChange(event.target.value)}
                        value={allReports ? selectedReport.reportId : ""}
                        isDisabled={!selectedInitiative}
                    />
                </Grid>
                <Grid item>
                    {report(selectedReport.reportId)?.state === "unreleased" && (
                        <StatusReportDatePicker
                            selectedDate={reportDate(selectedReport.reportId)}
                            setNewDate={(date) => handleDateChange(date)}
                            minDate={minDate()}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default StatusReportFilters
