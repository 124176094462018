import {PortfolioReportData} from "../portfolioReportData"
import {distinct, isPresent} from "../../../../utils/arrays"
import {FilterPortfolios_initiatives_controlling_plan_overview} from "../types/FilterPortfolios"

export type OverviewType = "program" | "program_management" | "project"

export type Overview = FilterPortfolios_initiatives_controlling_plan_overview

export class PortfolioBudgetData {
    constructor(
        public readonly overviews: FilterPortfolios_initiatives_controlling_plan_overview[],
        public readonly years: number[],
        private readonly all: FilterPortfolios_initiatives_controlling_plan_overview[]
    ) {}

    public inYear(year: number) {
        const filtered = this.overviews.filter((overview) => overview.period === year)
        return new PortfolioBudgetData(filtered, this.years, this.all)
    }

    withControllingPlan(controllingPlanId: string) {
        const filtered = this.overviews.filter((overview) => overview.controlling_plan_id === controllingPlanId)
        return new PortfolioBudgetData(filtered, this.years, this.all)
    }

    public havingBudget() {
        return new PortfolioBudgetData(
            this.overviews.filter((overview) => overview.budget),
            this.years,
            this.all
        )
    }

    public havingPlanned() {
        return new PortfolioBudgetData(
            this.overviews.filter((overview) => overview.planned),
            this.years,
            this.all
        )
    }

    public havingActual() {
        return new PortfolioBudgetData(
            this.overviews.filter((overview) => overview.actual),
            this.years,
            this.all
        )
    }

    public withoutType(type: OverviewType) {
        return new PortfolioBudgetData(
            this.overviews.filter((overview) => overview.type !== type),
            this.years,
            this.all
        )
    }

    public groupByControllingPlan(): {controllingPlanId: string; name: string; data: PortfolioBudgetData}[] {
        return this.controllingPlanIds().map((id) => {
            return {
                controllingPlanId: id,
                name: this.name(id) ?? "",
                data: this.withControllingPlan(id),
            }
        })
    }

    controllingPlanIds(): string[] {
        return this.overviews
            .map((overview) => overview?.controlling_plan_id)
            .filter(isPresent)
            .filter(distinct)
    }

    name(controllingPlanId: string) {
        return this.overviews.find((overview) => overview.controlling_plan_id === controllingPlanId)?.name
    }

    public budget(): number {
        return this.overviews.reduce((total, overview) => total + (overview.budget ?? 0), 0)
    }

    public planned(): number {
        return this.overviews.reduce((total, overview) => total + (overview.planned ?? 0), 0)
    }

    public actual(): number {
        return this.overviews.reduce((total, overview) => total + (overview.actual ?? 0), 0)
    }

    public sumBudget(data: PortfolioBudgetData): number {
        return data.all.reduce(function (a, b) {
            return a + (b.budget ?? 0)
        }, 0)
    }

    public sumBudgetRow(data: PortfolioBudgetData): number {
        return data.overviews.reduce(function (a, b) {
            return a + (b.budget ?? 0)
        }, 0)
    }

    public sumPlanned(data: PortfolioBudgetData): number {
        return data.all.reduce(function (a, b) {
            return a + (b.planned ?? 0)
        }, 0)
    }

    public sumPlannedRow(data: PortfolioBudgetData): number {
        return data.overviews.reduce(function (a, b) {
            return a + (b.planned ?? 0)
        }, 0)
    }

    public sumActual(data: PortfolioBudgetData): number {
        return data.all.reduce(function (a, b) {
            return a + (b.actual ?? 0)
        }, 0)
    }

    public sumActualRow(data: PortfolioBudgetData): number {
        return data.overviews.reduce(function (a, b) {
            return a + (b.actual ?? 0)
        }, 0)
    }
}

export const toBudgetData = (data: PortfolioReportData, yearsDisplayed?: (period: Overview) => boolean) => {
    const projectOverviews = data.projects().flatMap((project) => project.controlling_plan?.overview ?? [])
    const programOverviews = data
        .programs()
        .flatMap((program) => (program.controlling_plan?.overview ?? []))
        .filter((program) => program?.type === "program_management")

    const overviews = [...projectOverviews, ...programOverviews]

    const filteredOverviews = () => {
        if (yearsDisplayed) {
            return overviews.filter(isPresent).filter((o) => yearsDisplayed(o)) ?? []
        }
        return overviews.filter(isPresent) ?? []
    }

    const years = filteredOverviews()
        .map((overview) => overview.period)
        .filter(isPresent)
        .filter(distinct)
        .sort()
    return new PortfolioBudgetData(filteredOverviews(), years, overviews)
}
