import {IconProp} from "@fortawesome/fontawesome-svg-core"
import {TFunction} from "react-i18next"

export const PortfolioDropdown = (
    portfolioOptions: {
        name: string
        id: string
    }[]
) => {
    return {
        title: "portfolio",
        options: portfolioOptions,
        icon: "building",
        type: "portfolio",
    }
}

export const OrgUnitDropdown = (
    orgUnitOptions: {
        name: string
        id: string
    }[]
) => {
    return {
        title: "organization_unit",
        options: orgUnitOptions,
        icon: "book",
        type: "organisation_unit",
    }
}

export const StatusDropdown = (t: TFunction<"translations", undefined>) => {
    return {
        title: "status",
        options: [
            {name: `${t("all")}`, id: ""},
            {name: t("status-in-bearbeitung"), id: "In Bearbeitung"},
            {name: t("status-identifiziert"), id: "Identifiziert"},
            {name: t("status-vorbereitung"), id: "Vorbereitung"},
            {name: t("status-sistiert"), id: "Sistiert"},
            {name: t("status-storniert"), id: "Storniert"},
            {name: t("status-abgebrochen"), id: "Abgebrochen"},
            {name: t("status-abgeschlossen"), id: "Abgeschlossen"},
        ],
        icon: "tasks",
        type: "status",
    }
}

export const CategoryDropdown = (t: TFunction<"translations", undefined>) => {
    return {
        title: "category",
        options: [
            {name: `${t("all")}`, id: ""},
            {name: t("category-digitalisierung"), id: "Digitalisierung"},
            {name: t("category-digitalisierung-schwerpunkt"), id: "Digitalisierung Schwerpunkt"},
        ],
        icon: "th",
        type: "category",
    }
}

export const KindDropdown = (t: TFunction<"translations", undefined>) => {
    return {
        title: "kind",
        options: [
            {name: `${t("all")}`, id: ""},
            {name: t("kind-non-ict"), id: "Non-ICT"},
            {name: t("kind-ict"), id: "ICT"},
        ],
        icon: "th-large",
        type: "kind",
    }
}

export const TypeDropdown = (t: TFunction<"translations", undefined>) => {
    return {
        title: "type",
        options: [
            {name: `${t("all")}`, id: ""},
            {name: t("type-life-cycle"), id: "Life Cycle"},
            {name: t("type-innovation"), id: "Innovation"},
            {name: t("type-governance"), id: "Governance"},
        ],
        icon: "book",
        type: "type",
    }
}

export const CrossSectionSelectionDropdown = (t: TFunction<"translations", undefined>) => {
    return {
        title: "cross_section_selection",
        options: [
            {name: `${t("all")}`, id: ""},
            {name: t("cross-section-allgemeine-verwaltung"), id: "Allgemeine Verwaltung"},
            {
                name: t("cross-section-öffentliche-ordnung-bevölkerung-sicherheit-verteidigung"),
                id: "Öffentliche Ordnung, Bevölkerung, Sicherheit, Verteidigung",
            },
            {name: t("cross-section-bildung"), id: "Bildung"},
            {name: t("cross-section-kultur-sport-und-freizeit-kirche"), id: "Kultur, Sport und Freizeit, Kirche"},
            {name: t("cross-section-gesundheit"), id: "Gesundheit"},
            {name: t("cross-section-soziale-sicherheit"), id: "Soziale Sicherheit"},
            {name: t("cross-section-verkehr-und-nachrichtenübermittlung"), id: "Verkehr und Nachrichtenübermittlung"},
            {name: t("cross-section-umweltschutz-und-raumordnung"), id: "Umweltschutz und Raumordnung"},
            {name: t("cross-section-volkswirtschaft"), id: "Volkswirtschaft"},
            {name: t("cross-section-finanzen-und-steuern"), id: "Finanzen und Steuern"},
            {name: t("cross-section-bau--und-wohnungswesen"), id: "Bau- und Wohnungswesen"},
            {name: t("cross-section-geschäftspartner"), id: "Geschäftspartner"},
            {name: t("cross-section-geographie"), id: "Geographie"},
            {name: t("cross-section-statistik"), id: "Statistik"},
            {name: t("cross-section-kdi-relevanz"), id: "KDI-Relevanz"},
        ],

        icon: "th",
        type: "cross_section_selection",
    }
}

export const ExecutionTypeDropdown = (t: TFunction<"translations", undefined>) => {
    return {
        title: "execution_type",
        options: [
            {name: `${t("all")}`, id: ""},
            {name: t("execution-type-agile"), id: "Agil"},
            {name: t("execution-type-hybrid"), id: "Hybrid"},
            {name: t("execution-type-classic"), id: "Klassisch"},
        ],
        icon: "fa-arrows-split-up-and-left",
        type: "execution_type",
    }
}

export const ThemeTagsDropdown = (
    createOptions: () => {
        name: string
        id: string
    }[]
) => {
    return {
        title: "theme_tags",
        options: createOptions(),
        icon: "tags",
        type: "theme_tags",
    }
}
