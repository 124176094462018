import {gql} from "graphql-tag"

export const ReleasedOrInReviewFragment = gql`
    fragment ReleasedOrInReviewData on status_report_status_report {
        actual_end_date
        actual_start_date
        status_report_attachments {
            filename
            statusreport_id
        }
        description
        external_project_id
        external_program_id
        id
        next_milestones
        past_successes
        planned_start_date
        planned_end_date
        portfolio
        project_manager_user {
            id
            full_name
        }
        project_name
        project_phase
        project_status
        report_date
        state
        report_title
        state_histories {
            new_state
            timestamp
        }
        project_states {
            id
            state
            type
            status_report_id
            status_report {
                report_date
            }
        }
        milestones {
            actual_date
            description
            planned_date
            status
            id
            status_report_id
        }
        initiative {
            type
            deputy_manager
            sponsor
            deputy_sponsor
        }
    }
`
export const ReleasedOrInReviewFragmentVertraulich = gql`
    fragment ReleasedOrInReviewDataVertraulich on status_report_status_report {
        actual_costs
        status_report_attachments {
            filename
            statusreport_id
        }
        planned_costs
        project_costs(order_by: {date: asc}) {
            amount
            date
            id
            type
            status_report_id
        }
        project_states {
            comment
        }
        risks {
            damage_extent
            description
            assignee_user {
                id
                full_name
            }
            estimated_cost
            id
            next_assessment
            probability_of_occurrence
            status
            status_report_id
            countermeasures {
                assignee_user {
                    id
                    full_name
                }
                description
                due_date
                id
                status
            }
        }
        problems {
            assignee
            due_date
            id
            name
            status_report_id
            status
            assignee_user {
                id
                full_name
            }
        }
        portfolio_program {
            deputy_program_lead
            deputy_sponsor
            sponsor
        }
        change_requests {
            id
            description
            status
            status_report_id
            assignee_user {
                id
                full_name
            }
            reporter_user {
                id
                full_name
            }
        }
    }
`

export const MilestoneFragment = gql`
    fragment MilestoneFragment on jira_rm_milestones {
        actual_end_date
        status
        planned_end_date
        issue_id
        summary
    }
`

export const RiskFragment = gql`
    fragment RiskFragment on jira_rm_risks {
        damage_impact
        estimated_costs
        issue_id
        probability
        status
        summary
        assignee_user {
            id
            full_name
        }
        next_assessment
        countermeasures {
            assignee_user {
                id
                full_name
            }
            summary
            due_date
            issue_id
            status
        }
    }
`

export const ProblemFragment = gql`
    fragment ProblemFragment on jira_rm_problem {
        issue_id
        status
        summary
        assignee_user {
            id
            full_name
        }
        due_date
    }
`

export const ChangeRequestFragment = gql`
    fragment ChangeRequestFragment on jira_rm_change_request {
        issue_id
        summary
        status
        assignee_user {
            id
            full_name
        }
        reporter_user {
            id
            full_name
        }
    }
`

export const UnreleasedOrRejectedFragment = gql`
    fragment UnreleasedOrRejectedData on status_report_status_report {
        id
        description
        external_project_id
        external_program_id
        next_milestones
        past_successes
        state
        report_date
        report_title
        project_states {
            id
            state
            type
            status_report_id
            status_report {
                report_date
            }
            comment
        }
        status_report_attachments {
            filename
            statusreport_id
        }
        initiative {
            type
            status
            summary
            phase
            actual_end_date
            actual_start_date
            milestones(where: {reportable: {_eq: true}}) {
                ...MilestoneFragment
            }
            planned_end_date
            planned_start_date
            portfolio {
                name
            }
            manager_user {
                id
                full_name
            }
            deputy_manager
            sponsor
            deputy_sponsor
            risks(where: {reportable: {_eq: true}}) {
                ...RiskFragment
            }
            problems(where: {reportable: {_eq: true}}) {
                ...ProblemFragment
            }
            change_requests(where: {reportable: {_eq: true}}) {
                ...ChangeRequestFragment
            }
            children {
                summary
                milestones {
                    ...MilestoneFragment
                }
                risks {
                    ...RiskFragment
                }
                problems(where: {reportable: {_eq: true}}) {
                    ...ProblemFragment
                }
                change_requests(where: {reportable: {_eq: true}}) {
                    ...ChangeRequestFragment
                }
            }
        }
    }
    ${MilestoneFragment}
    ${RiskFragment}
    ${ProblemFragment}
    ${ChangeRequestFragment}
`