import React from "react"
import {ResponsiveBar} from "@nivo/bar"
import {useTranslation} from "react-i18next"
import styles from "../../ReportStyles.module.scss"
import {GraphDialogProps} from "../PortfolioReportMainContent"
import {FilterPortfolios_initiatives} from "../types/FilterPortfolios"

interface Props {
    data: FilterPortfolios_initiatives[]
    programOrProject: "program" | "project"
    setTableDialogOpen?: (values: GraphDialogProps) => void
    printVersion?: boolean
}

const ByPhaseBarchart: React.FC<Props> = ({data, programOrProject, setTableDialogOpen, printVersion}) => {
    const {t} = useTranslation("translations")

    const projectChartData: {numOfProjectsOrPrograms: number; phase: string}[] = [
        {
            numOfProjectsOrPrograms: 0,
            phase: "registriert",
        },
        {
            numOfProjectsOrPrograms: 0,
            phase: "vorbereitung",
        },
        {
            numOfProjectsOrPrograms: 0,
            phase: "initialisierung",
        },
        {
            numOfProjectsOrPrograms: 0,
            phase: "umsetzung",
        },
        {
            numOfProjectsOrPrograms: 0,
            phase: "konzept",
        },
        {
            numOfProjectsOrPrograms: 0,
            phase: "realisierung",
        },
        {
            numOfProjectsOrPrograms: 0,
            phase: "einführung",
        },
        {
            numOfProjectsOrPrograms: 0,
            phase: "abschluss",
        },
        {
            numOfProjectsOrPrograms: 0,
            phase: "abgeschlossen",
        },
    ]

    const programChartData: {numOfProjectsOrPrograms: number; phase: string}[] = [
        {
            numOfProjectsOrPrograms: 0,
            phase: "registriert",
        },
        {
            numOfProjectsOrPrograms: 0,
            phase: "vorbereitung",
        },
        {
            numOfProjectsOrPrograms: 0,
            phase: "initialisierung",
        },
        {
            numOfProjectsOrPrograms: 0,
            phase: "durchführung",
        },
        {
            numOfProjectsOrPrograms: 0,
            phase: "abschluss",
        },
        {
            numOfProjectsOrPrograms: 0,
            phase: "abgeschlossen",
        },
    ]

    const generateChartData = (chartData: {numOfProjectsOrPrograms: number; phase: string}[]) => {
        data.forEach((projectOrProgram) => {
            chartData.forEach((y) => {
                if (y.phase.toLocaleLowerCase() === projectOrProgram.phase?.toLocaleLowerCase()) {
                    y.numOfProjectsOrPrograms += 1
                }
            })
        })
        return chartData
    }

    return (
        <ResponsiveBar
            data={programOrProject === "project" ? generateChartData(projectChartData) : generateChartData(programChartData)}
            colors={{scheme: "paired"}}
            colorBy="indexValue"
            keys={["numOfProjectsOrPrograms"]}
            indexBy="phase"
            margin={{
                top: 30,
                right: 30,
                bottom: 85,
                left: 60,
            }}
            padding={0.5}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="inherit:darker(1.6)"
            animate={!printVersion}
            theme={{
                fontSize: 12,
                labels: {
                    text: {
                        fontWeight: 800,
                        fontSize: 14,
                    },
                },
            }}
            axisBottom={{
                legend: <tspan style={{fontWeight: 550}}>{t("phase")}</tspan>,
                legendOffset: 80,
                legendPosition: "middle",
                format: (value) => {
                    return t(`phase-${value}`)
                },
                tickRotation: -37,
            }}
            axisLeft={{
                legend: (
                    <tspan style={{fontWeight: 550}}>{programOrProject === "program" ? t("total-programs") : t("total-projects")}</tspan>
                ),
                legendOffset: -50,
                legendPosition: "middle",
            }}
            tooltip={(item) => {
                return (
                    <div className={styles.graphToolTip}>
                        <div className={styles.colorBox} style={{backgroundColor: `${item.color}`}}></div>
                        <p>
                            {t(`phase-${item.indexValue}`)}
                            {` - `}
                            <b>{item.value}</b>
                        </p>
                    </div>
                )
            }}
            onClick={(node) => {
                setTableDialogOpen && setTableDialogOpen({graphType: "phase", filterValue: node.data.phase, programOrProject})
            }}
        />
    )
}

export default ByPhaseBarchart
