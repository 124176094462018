import {ComputedCell, ResponsiveHeatMap} from "@nivo/heatmap"
import {useTranslation} from "react-i18next"
import styles from "../ReportStyles.module.scss"
import {useEffect, useState} from "react"
import {getProbabilityLevel, getDamageImpactLevel} from "./riskData"
import {GetRisksByInitiative_initiatives_risks} from "./types/GetRisksByInitiative"

interface ChartDataType {
    id: string
    data: {x: string; y: number; numOfRisks: number}[]
}

const chartData: ChartDataType[] = [
    {
        id: "Hoch", // 4 points
        data: [
            {
                x: "Niedrig", // 1 point
                y: 4,
                numOfRisks: 0,
            },
            {
                x: "Mittel", // 2 points
                y: 8,
                numOfRisks: 0,
            },
            {
                x: "Hoch", // 3 points
                y: 12,
                numOfRisks: 0,
            },
            {
                x: "Absolut", // 4 points
                y: 16,
                numOfRisks: 0,
            },
        ],
    },
    {
        id: "Wesentlich", // 3 points
        data: [
            {
                x: "Niedrig", // 1 point
                y: 3,
                numOfRisks: 0,
            },
            {
                x: "Mittel", // 2 points
                y: 6,
                numOfRisks: 0,
            },
            {
                x: "Hoch", // 3 points
                y: 9,
                numOfRisks: 0,
            },
            {
                x: "Absolut", // 4 points
                y: 12,
                numOfRisks: 0,
            },
        ],
    },
    {
        id: "Mittel", // 2 points
        data: [
            {
                x: "Niedrig", // 1 point
                y: 2,
                numOfRisks: 0,
            },
            {
                x: "Mittel", // 2 points
                y: 4,
                numOfRisks: 0,
            },
            {
                x: "Hoch", // 3 points
                y: 6,
                numOfRisks: 0,
            },
            {
                x: "Absolut", // 4 points
                y: 8,
                numOfRisks: 0,
            },
        ],
    },
    {
        id: "Tief", // 1 points
        data: [
            {
                x: "Niedrig", // 1 point
                y: 1,
                numOfRisks: 0,
            },
            {
                x: "Mittel", // 2 points
                y: 2,
                numOfRisks: 0,
            },
            {
                x: "Hoch", // 3 points
                y: 3,
                numOfRisks: 0,
            },
            {
                x: "Absolut", // 4 points
                y: 4,
                numOfRisks: 0,
            },
        ],
    },
]

const generateUpdatedChartData = (
    risks: GetRisksByInitiative_initiatives_risks[],
    chartData: {id: string; data: {x: string; y: number; numOfRisks: number}[]}[]
) => {
    // Mappings to associate damage_impact and probability with chartData properties
    const damageImpactMap: {[key: string]: string} = {
        Hoch: "Hoch",
        Mittel: "Mittel",
        Gering: "Gering",
        Wesentlich: "Wesentlich",
    }

    const probabilityMap: {[key: string]: string} = {
        Tief: "Tief",
        Hoch: "Hoch",
        Mittel: "Mittel",
        Niedrig: "Niedrig",
    }

    // Clone the chartData to avoid mutating the original
    const newChartData = chartData.map((entry) => ({
        ...entry,
        data: entry.data.map((item) => ({...item})), // Deep clone data array
    }))

    // Iterate over each risk
    risks.forEach((risk) => {
        if (risk.damage_impact && risk.probability) {
            const chartId = damageImpactMap[risk.damage_impact] || risk.damage_impact
            const probabilityLabel = probabilityMap[risk.probability] || risk.probability

            // Find the corresponding chartData object and data entry
            const chartEntry = newChartData.find((entry) => entry.id === chartId)
            const dataEntry = chartEntry?.data.find((item) => item.x === probabilityLabel)

            // Increment the numOfRisks if both chartEntry and dataEntry are found
            if (dataEntry) {
                dataEntry.numOfRisks += 1
            }
        }
    })

    return newChartData
}

interface Props {
    data: GetRisksByInitiative_initiatives_risks[]
    onCellClick: (value: {damage_impact: string; probability: string; id: string} | null) => void
    selectedCell: {
        damage_impact: string
        probability: string
        id: string
    } | null
}

export const RiskProbabilityHeatMap: React.FC<Props> = ({data, onCellClick, selectedCell}) => {
    const {t} = useTranslation("translations")

    const [calculatedChartData, setCalculatedChartData] = useState<ChartDataType[]>(chartData)
    const [hoverCell, setHoverCell] = useState<{
        damage_impact: string
        probability: string
        id: string
    } | null>(null)

    useEffect(() => {
        setCalculatedChartData(generateUpdatedChartData(data, chartData))
    }, [data])

    const determineColor = (riskLevel: number) => {
        if (riskLevel <= 2) {
            return "#98D8AA"
        } else if (riskLevel >= 3 && riskLevel <= 9) {
            return "#F3E99F"
        } else return "#FF6D60"
    }

    const customMouseEnter = (
        e: React.MouseEvent,
        cell: ComputedCell<{
            x: string
            y: number
            numOfRisks: number
        }>
    ) => {
        if (hoverCell && hoverCell.id === cell.id) {
            return
        } else setHoverCell({damage_impact: cell.serieId, probability: cell.data.x, id: cell.id})
    }

    return (
        <ResponsiveHeatMap
            data={calculatedChartData}
            cellComponent={({cell, ...cellProps}) => {
                if (cell.value === null) return null

                const isSelectedCell = cell.serieId === selectedCell?.damage_impact && cell.data.x === selectedCell.probability

                const determineOpacity = () => {
                    if (hoverCell && hoverCell.id === cell.id) {
                        return 1
                    } else if (!isSelectedCell) {
                        return 0.25
                    } else if (isSelectedCell) {
                        return cell.opacity
                    } else return 0.25
                }

                return (
                    <g
                        fillOpacity={selectedCell ? determineOpacity() : cell.opacity}
                        onClick={cellProps.onClick?.(cell)}
                        onMouseEnter={selectedCell ? (e: React.MouseEvent) => customMouseEnter(e, cell) : cellProps.onMouseEnter?.(cell)}
                        onMouseLeave={cellProps.onMouseLeave?.(cell)}
                        onMouseMove={cellProps.onMouseMove?.(cell)}
                        transform={`translate(${cell.x} ${cell.y})`}
                        style={{transition: "all 0.2s"}}
                    >
                        <rect
                            fill={determineColor(cell.data.y)}
                            height={cell.height}
                            stroke={cell.borderColor}
                            transform={`translate(${-0.5 * cell.width} ${-0.5 * cell.height})`}
                            width={cell.width}
                            style={{transition: "all 0.2s"}}
                        />
                        <text
                            dominantBaseline="central"
                            textAnchor="middle"
                            style={{fill: cell.labelTextColor, fontSize: "12px", fontWeight: "500"}}
                        >
                            {cell.label}
                        </text>
                    </g>
                )
            }}
            forceSquare={true}
            margin={{top: 60, right: 90, bottom: 60, left: 110}}
            axisTop={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 10,
                tickRotation: 0,
                legend: <tspan style={{fontWeight: 550}}>{t("risk-probability")}</tspan>,
                legendOffset: 50,
                legendPosition: "middle",
                truncateTickAt: 0,
                format: (tick) => {
                    return `${t(`risk-probability-${tick.replace(" ", "-").toLowerCase()}`)} (${getProbabilityLevel(tick)})`
                },
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: <tspan style={{fontWeight: 550}}>{t("risk-report-auswirkungen")}</tspan>,
                legendPosition: "middle",
                legendOffset: -95,
                truncateTickAt: 0,
                format: (tick) => {
                    if (tick === "Wesentlich") return `(${getDamageImpactLevel(tick)})`
                    return `${t(`damage-impact-${tick.toLowerCase()}`)} (${getDamageImpactLevel(tick)})`
                },
            }}
            colors={{
                type: "quantize",
                colors: ["#98D8AA", "#F3E99F", "#FF6D60"],
                steps: 4,
            }}
            borderColor="#969696"
            emptyColor="#555555"
            labelTextColor={"#000"}
            inactiveOpacity={0.25}
            label={(d) => d.data.y.toString()}
            hoverTarget="cell"
            tooltip={(item) => {
                return (
                    <div className={styles.graphToolTip} style={{flexDirection: "column", width: "250px"}}>
                        <div className={styles.projectNameWrapper}>
                            <div className={styles.colorBox} style={{backgroundColor: `${determineColor(item.cell.data.y)}`}}></div>
                            <p>{`${item.cell.data.numOfRisks.toString()} ${t("risks")}`}</p>
                        </div>
                        <div className={styles.valuesWrapper} style={{width: "100%"}}>
                            <div className={styles.value}>
                                <p style={{textAlign: "left"}}>{`${t("damage-impact")}: `}</p>
                                <span style={{fontWeight: "bolder", textAlign: "right"}}>
                                    {item.cell.serieId === "Wesentlich"
                                        ? `(${getDamageImpactLevel(item.cell.serieId)})`
                                        : `${t(`damage-impact-${item.cell.serieId.toLowerCase()}`)} (${getDamageImpactLevel(
                                              item.cell.serieId
                                          )})`}
                                </span>
                            </div>
                            <div className={styles.value}>
                                <p style={{textAlign: "left"}}>{`${t("risk-probability")}: `}</p>
                                <p style={{fontWeight: "bolder"}}>
                                    {`${t(`risk-probability-${item.cell.data.x.replace(" ", "-").toLowerCase()}`)} (${getProbabilityLevel(
                                        item.cell.data.x
                                    )})`}
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }}
            onClick={(cell) => {
                setHoverCell(null)
                if (selectedCell?.id === cell.id) {
                    onCellClick(null)
                } else onCellClick({damage_impact: cell.serieId, probability: cell.data.x, id: cell.id})
            }}
            onMouseLeave={() => setHoverCell(null)}
            animate={true}
            motionConfig="gentle"
        />
    )
}
