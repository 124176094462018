import React, {useCallback, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import {SelectChangeEvent} from "@mui/material/Select"
import PageWrapper from "../../../components/PageWrapper"
import FilterDropdownsSection, {DropdownProps} from "../../../components/FilterDropdownsSection"
import {gql, useQuery} from "@apollo/client"
import {FilterPortfolios, FilterPortfoliosVariables} from "./types/FilterPortfolios"
import PortfolioReportMainContent from "./PortfolioReportMainContent"
import CircularProgress from "@mui/material/CircularProgress"
import {useReactToPrint} from "react-to-print"
import {getPageMargins} from "../../../utils/printStyles"
import ReportPrintVersion from "./ReportPrintVersion"
import {PortfolioReportData} from "./portfolioReportData"
import {capitalizeFirstLetter} from "../../../utils/strings"
import {GetPortfolioReportFilterOptions} from "./types/GetPortfolioReportFilterOptions"
import {distinct, isPresent} from "../../../utils/arrays"
import {
    PortfolioDropdown,
    StatusDropdown,
    OrgUnitDropdown,
    CategoryDropdown,
    KindDropdown,
    TypeDropdown,
    CrossSectionSelectionDropdown,
    ExecutionTypeDropdown,
    ThemeTagsDropdown,
} from "../dropdowns"

const getPortfolioReportFilterOptions = gql`
    query GetPortfolioReportFilterOptions {
        portfolio: jira_rm_portfolio {
            name
            initiative_org_units: initiatives(distinct_on: organisation_unit, where: {organisation_unit: {_is_null: false}}) {
                organisation_unit
            }
            initiative_theme_tags: initiatives(distinct_on: theme_tags, where: {theme_tags: {_is_null: false}}) {
                theme_tags
            }
        }
    }
`

const filterPortfolios = gql`
    query FilterPortfolios($whereInitiative: jira_rm_initiative_bool_exp!) {
        initiatives: jira_rm_initiative(where: $whereInitiative) {
            actual_start_date
            actual_end_date
            planned_start_date
            planned_end_date
            issue_id
            initiative_type
            score_risk
            score_size
            score_value
            type
            portfolio {
                name
            }
            theme_tags
            phase
            kind
            priority
            execution_type
            organisation_unit
            category
            summary
            status
            cross_section_selections
            overview_url
            controlling_plan {
                overview {
                    controlling_plan_id
                    name
                    type
                    period
                    expense_type
                    budget
                    planned
                    actual
                }
            }
            status_reports(where: {state: {_eq: "released"}}) {
                id
                report_date
                project_states {
                    type
                    state
                    id
                }
            }
        }
    }
`

const PortflioReport = () => {
    const {t} = useTranslation("translations")

    const printStatusReportRef = React.useRef<HTMLDivElement | null>(null)

    const [printing, setPrinting] = React.useState<boolean>(false)

    const [portfolioOptions, setPortfolioOptions] = useState<{name: string; id: string}[]>([])
    const [orgUnitOptions, setOrgUnitOptions] = useState<{name: string; id: string}[]>([])

    const [overviewFilterOptions, setOverviewFilterOptions] = React.useState({
        portfolio: "",
        status: "",
        organisation_unit: "",
        kind: "",
        category: "",
        type: "",
        cross_section_selection: "",
        execution_type: "",
        theme_tags: "",
    })
    let filterVariables = {}
    Object.entries(overviewFilterOptions)
        .filter((x) => x[1] !== "")
        .forEach((x) => {
            if (x[0] === "portfolio") {
                filterVariables = {
                    ...filterVariables,
                    portfolio: {name: {_eq: x[1]}},
                }
            } else if (x[0] === "cross_section_selection") {
                filterVariables = {
                    ...filterVariables,
                    cross_section_selections: {_contains: x[1]},
                }
            } else if (x[0] === "theme_tags") {
                filterVariables = {
                    ...filterVariables,
                    theme_tags: {_contains: x[1]},
                }
            } else {
                filterVariables = {
                    ...filterVariables,
                    [x[0]]: {_eq: x[1]},
                }
            }
        })

    const {data, error} = useQuery<GetPortfolioReportFilterOptions>(getPortfolioReportFilterOptions)
    const {
        data: filteredData,
        loading: filteredLoading,
        error: filteredDataError,
    } = useQuery<FilterPortfolios, FilterPortfoliosVariables>(filterPortfolios, {
        variables: {
            whereInitiative: filterVariables,
        },
    })
    if (error || filteredDataError) throw Error(error?.message || filteredDataError?.message)

    const createOptions = useCallback(
        (array: string[], uppercase?: boolean) => [
            {name: `${t("all")}`, id: ""},
            ...array.map((x) => {
                if (uppercase) {
                    return {name: capitalizeFirstLetter(x), id: x}
                } else {
                    return {name: x, id: x}
                }
            }),
        ],
        [t],
    )

    const portfolios =
        data?.portfolio
            .map((portfolio) => portfolio.name)
            .filter(isPresent)
            .filter(distinct) ?? []

    const themeTags: string[] =
        data?.portfolio
            .flatMap((portfolio) => {
                return portfolio?.initiative_theme_tags.flatMap(initiative => initiative.theme_tags).filter(isPresent) ?? []
            })
            .filter(distinct) ?? []

    const getAllOrgUnits = useCallback(() => {
        const result =
            data?.portfolio
                .map((portfolio) => {
                    return portfolio?.initiative_org_units.flatMap(initiative => initiative.organisation_unit).filter(isPresent) ?? []

                })
                .filter(isPresent)
                .filter(distinct) ?? []

        return Array.from(new Set(result.flat()))
    }, [data])

    useEffect(() => {
        if (!filteredData) return

        if (!overviewFilterOptions.portfolio) {
            setOrgUnitOptions(createOptions(getAllOrgUnits()))
        } else {
            const filteredOrgUnits = filteredData?.initiatives.map((initiative) => initiative.organisation_unit).filter(isPresent)
            setOrgUnitOptions(createOptions(filteredOrgUnits))
        }

        if (!overviewFilterOptions.organisation_unit) {
            setPortfolioOptions(createOptions(portfolios))
        } else {
            const filteredPortfolios = filteredData?.initiatives.map((initiative) => initiative.portfolio?.name).filter(isPresent)
            setPortfolioOptions(createOptions(filteredPortfolios))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredData, getAllOrgUnits, createOptions, overviewFilterOptions.portfolio, overviewFilterOptions.organisation_unit])

    const reportData = new PortfolioReportData(filteredData?.initiatives ?? [])

    const dropdowns: DropdownProps[] = [
        PortfolioDropdown(portfolioOptions),
        StatusDropdown(t),
        OrgUnitDropdown(orgUnitOptions),
        CategoryDropdown(t),
        KindDropdown(t),
        TypeDropdown(t),
        CrossSectionSelectionDropdown(t),
        ExecutionTypeDropdown(t),
        ThemeTagsDropdown(() => createOptions(themeTags)),
    ]

    const handleChange = (event: SelectChangeEvent) => {
        setOverviewFilterOptions({...overviewFilterOptions, [event.target.name]: event.target.value})
    }

    const handlePrint = useReactToPrint({
        content: () => printStatusReportRef.current,
        pageStyle: `@media print {  .printDisplay {display: block !important;}}`,
    })

    const handlePrintingReport = () => {
        setPrinting(true)
        //NOTE: The Nivo charts would not load in time when simply using "display: none" as suggested by react-to-print to print components that are only viewable during print and not at all on @media: screen. Therefore, this timeout to toggle the printing state will switch the view of the page to the "print friendly" component for a moment to allow for the DOM to load the charts. Without this the page will have all charts show as empty in the print-preview.
        setTimeout(() => {
            handlePrint()
            setPrinting(false)
        }, 200)
    }

    if (filteredLoading)
        return (
            <div>
                <p>{t("loading")}</p>
                <CircularProgress />
            </div>
        )

    return (
        <>
            <style>{getPageMargins()}</style>
            <div ref={printStatusReportRef}>
                <PageWrapper headerTitle={t("portfolio-report")} icon={"list" as IconProp}>
                    <FilterDropdownsSection
                        handleChange={handleChange}
                        handlePrint={handlePrintingReport}
                        filterValues={overviewFilterOptions}
                        dropdowns={dropdowns}
                    />
                    {/* //NOTE: ReportPrintVersion is the PortfolioReport redone entirely without Mui components. The combination of
                    react-to-print, SVG Nivo Charts & Mui Grid system (which is needed for the graphs on @media: screen view) was causing
                    the graphs to completely jump and leave their container divs in print-preview. By creating a conditional print-friendly
                    component for the PortfolioReport that only shows up as the document is being printed was the the only way to solve this
                    issue. */}
                    <>{printing ? <ReportPrintVersion data={reportData} /> :
                        <PortfolioReportMainContent data={reportData} />}</>
                </PageWrapper>
            </div>
        </>
    )
}

export default PortflioReport
