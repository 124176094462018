import React, {useState} from "react"
import styles from "../ReportStyles.module.scss"
import {useTranslation} from "react-i18next"
import {RiskReportData} from "./riskReportData"
import {RiskProbabilityHeatMap} from "./RiskProbabilityHeatMap"
import RisksTable from "./RisksTable"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEmptySet} from "@fortawesome/pro-regular-svg-icons"

interface Props {
    data: RiskReportData
}

const ReportPrintVersion: React.FC<Props> = ({data}) => {
    const {t} = useTranslation("translations")

    const [selectedCell, setSelectedCell] = useState<{damage_impact: string; probability: string; id: string} | null>(null)

    const openRisks = data.getRiskByStatus(["Open", "In Progress"])

    const filteredData = openRisks.filter(
        (risk) => risk.damage_impact === selectedCell?.damage_impact && risk.probability === selectedCell.probability
    )

    const riskTableData = selectedCell ? filteredData : data.risks()

    return (
        <div className={styles.reportPrintVersion}>
            <div className={`${styles.portfolioReportArea}`}>
                {data.risks().length > 0 ? (
                    <>
                        {/* Overview Section */}
                        <div className={`${styles.gridContainerSection}`} style={{margin: "5px 0px"}}>
                            <div className={styles.topLineOverview}>
                                <div style={{textAlign: "left"}}>
                                    <div className={styles.reportHeader}>
                                        <h3>{t("risks")} </h3>
                                        <div className={styles.lineDecoration}></div>
                                    </div>
                                </div>
                                <div className={styles.activeReportsWrapper}>
                                    <p>
                                        <span className={styles.activeReportText}>{t("active-programs")}:</span>
                                        <span className={styles.activeReportNumber}>{data.numberOfPrograms()}</span>
                                    </p>
                                    <p>
                                        <span className={styles.activeReportText}>{t("active-projects")}:</span>
                                        <span className={styles.activeReportNumber}>{data.numberOfProjects()}</span>
                                    </p>
                                </div>
                            </div>
                            <div>
                                <p className={styles.descriptionText}>{t("risks-report-overview-description-text-1")}</p>
                                <p className={styles.descriptionText}>{t("risks-report-overview-description-text-2")}</p>
                                <p className={styles.descriptionText}>{t("risks-report-overview-description-text-3")}</p>
                            </div>
                            <div style={{height: "300px", width: "720px"}} className={`${styles.gridItem} ${styles.portEvalBubbleChart}`}>
                                {/* <h5>{t("portfolio-report-portfolio-evaluation")}</h5> */}
                                <RiskProbabilityHeatMap data={openRisks} onCellClick={setSelectedCell} selectedCell={selectedCell} />
                            </div>
                        </div>

                        {/* Risks Table Section */}
                        <div className={`${styles.gridContainerSection}`} style={{marginTop: "-30px"}}>
                            <div>
                                <div className={styles.riskSummary}>
                                    <div style={{margin: "20px 0px"}}>
                                        <p>
                                            {`${t("damage-impact")}: `}{" "}
                                            <span>
                                                {selectedCell ? t(`damage-impact-${selectedCell?.damage_impact.toLowerCase()}`) : t("all")}
                                            </span>
                                        </p>
                                    </div>
                                    <p style={{marginRight: "20px"}}>|</p>
                                    <div>
                                        <p>
                                            {`${t("risk-probability")}: `}{" "}
                                            <span>
                                                {selectedCell
                                                    ? t(`risk-probability-${selectedCell?.probability.replace(" ", "-").toLowerCase()}`)
                                                    : t("all")}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <RisksTable risks={riskTableData} />
                            </div>
                        </div>
                    </>
                ) : (
                    <div className={`${styles.gridContainerSection}`} style={{margin: "5px 0px"}}>
                        <div className={styles.noDataDivWrapper}>
                            <FontAwesomeIcon icon={faEmptySet} className={styles.emptyIcon} />
                            <p className={styles.emptyText}>{t("no-data-found-matching-filter")}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ReportPrintVersion
