import React from "react"
import Button from "@mui/material/Button"
import {useTranslation} from "react-i18next"
import {StatusReportState} from "../../../types/graphql-global-types"
import {useAuth} from "../../../auth/AuthProvider"
import {gql} from "graphql-tag"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import {DialogActions} from "@mui/material"
import {styled} from "@mui/material/styles"
import {GetStatusReportForAction} from "./types/GetStatusReportForAction"

export const StatusReportForActionFragment = gql`
    fragment StatusReportForActionFragment on status_report_status_report {
        id
        state
        initiative: initiative {
            issue_id
            manager
            deputy_manager
            sponsor
            deputy_sponsor
            overview_url
            latestReleasedReport: status_reports(where: {state: {_eq: "released"}}, order_by: {report_date: desc}, limit: 1) {
                id
            }
        }
    }
`

const ConfirmationDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },
}))

interface Props {
    statusReport: GetStatusReportForAction | undefined
    handleReportStateChange: (newState: StatusReportState) => void
}

interface ChangeStateButtonProps {
    actionTitle: string
    newState: StatusReportState
    color?: "error" | "inherit" | "primary" | "secondary" | "success" | "info" | "warning" | undefined
}

const StatusReportActions: React.FC<Props> = ({statusReport, handleReportStateChange}) => {
    const {t} = useTranslation("translations")
    const authUser = useAuth().current()

    const [reportState, setReportState] = React.useState<string>(statusReport?.status_report?.state ?? "")
    React.useEffect(() => {
        setReportState(statusReport?.status_report?.state ?? "")
    }, [statusReport])
    const initiative = statusReport?.status_report?.initiative ?? null

    const [openDialog, setOpenDialog] = React.useState(false)
    const handleOpenDialogConfirmation = () => setOpenDialog(true)
    const handleCloseDialogConfirmation = () => setOpenDialog(false)

    const isProjectManager = () => initiative?.manager === authUser?.email || initiative?.deputy_manager === authUser?.email
    const isSponsor = () => initiative?.sponsor === authUser?.email || initiative?.deputy_sponsor === authUser?.email

    const isLatestReleasedReport = () => initiative?.latestReleasedReport
        //TODO: Fix the typing on the "any" here:
        .some((latest: any) => statusReport?.status_report?.id === latest.id) ?? false

    const ChangeStateButton: React.FC<ChangeStateButtonProps> = ({actionTitle, color, newState}) => {
        return (
            <Button
                size="small"
                variant="contained"
                sx={
                    actionTitle === "ready-to-review"
                        ? {height: "30px", marginRight: "10px", minWidth: "140px"}
                        : {height: "30px", marginRight: "10px"}
                }
                color={color ? color : undefined}
                onClick={() => {
                    setOpenDialog(false)
                    setReportState(newState)
                    handleReportStateChange(newState)
                }}
            >
                {t(actionTitle)}
            </Button>
        )
    }

    return (
        <div>
            {(reportState === "unreleased" || reportState === "rejected") && isProjectManager() && (
                <ChangeStateButton newState={StatusReportState.in_review} actionTitle="ready-to-review" />
            )}
            {reportState === "in_review" && isSponsor() && (
                <div style={{display: "flex", marginLeft: "10px"}}>
                    <ChangeStateButton newState={StatusReportState.released} actionTitle="release" />
                    <ChangeStateButton newState={StatusReportState.rejected} actionTitle="reject" color="error" />
                </div>
            )}
            {isLatestReleasedReport() && isSponsor() && (
                <>
                    <Button
                        onClick={() => handleOpenDialogConfirmation()}
                        color="error"
                        size="small"
                        variant="contained"
                        sx={{height: "30px", margin: "0px 20px 0px 10px"}}
                    >
                        {t("cancel-report")}
                    </Button>
                    <ConfirmationDialog onClose={handleCloseDialogConfirmation} open={openDialog}
                                        sx={{minHeight: "600px"}}>
                        <DialogTitle>{t("cancel-report-confirmation-text")}</DialogTitle>
                        <DialogActions>
                            <ChangeStateButton newState={StatusReportState.canceled} actionTitle="yes" color="error" />
                            <Button
                                size="small"
                                variant="contained"
                                sx={{height: "30px"}}
                                color="inherit"
                                onClick={handleCloseDialogConfirmation}
                            >
                                {t("no")}
                            </Button>
                        </DialogActions>
                    </ConfirmationDialog>
                </>
            )}
        </div>
    )
}

export default StatusReportActions
