import {differenceInDays, max} from "date-fns"
import {
    GetReport,
    GetReport_releasedOrInReview_report,
    GetReport_releasedOrInReview_report_project_states_status_report_status_report,
    GetReport_releasedOrInReview_report_status_report_attachments,
    GetReport_unreleasedOrRejected_report,
    GetReport_unreleasedOrRejected_report_status_report_attachments,
} from "./types/GetReport"
import {AuthUser} from "../../../auth/auth"
import {RiskFragment} from "./types/RiskFragment"
import {MilestoneFragment} from "./types/MilestoneFragment"
import {ProblemFragment} from "./types/ProblemFragment"
import {ChangeRequestFragment} from "./types/ChangeRequestFragment"

export class StatusReportData {
    constructor(
        public readonly id: string,
        public readonly type: "program" | "project",
        public readonly overview: StatusReportOverview,
        public readonly attachments: StatusReportAttachments,
        public readonly summary: StatusReportSummary,
        public readonly states: StatusReportState[],
        public readonly milestones: StatusReportMilestone[],
        public readonly risks: StatusReportRisk[],
        public readonly problems: StatusReportProblem[],
        public readonly changeRequests: StatusReportChangeRequest[],
    ) {
    }

    currentState(): StatusReportState {
        return this.states.filter((state) => state.report_id === this.id)[0]
    }

    isEditableBy(user: AuthUser | null): boolean {
        if (user == null) return false

        if (this.overview.state === "unreleased") return this.isManager(user)
        if (this.overview.state === "rejected") return this.isManager(user)
        if (this.overview.state === "in_review") return this.isSponsor(user)
        return false
    }

    isManager(user: AuthUser): boolean {
        return this.overview.manager?.id === user.email || this.overview.deputy_manager_id === user.email
    }

    isSponsor(user: AuthUser): boolean {
        return this.overview.sponsor_id === user.email || this.overview.deputy_sponsor_id === user.email
    }
}

export interface StatusReportOverview {
    project_name: string
    manager: User | null
    deputy_manager_id: string | null
    sponsor_id: string | null
    deputy_sponsor_id: string | null
    project_status: string | null
    portfolio: string
    state: string
    phase: string | null
    planned_start_date: Date | null
    planned_end_date: Date | null
    planned_costs: number | null
    actual_start_date: Date | null
    actual_end_date: Date | null
    actual_costs: number | null
    report_date: Date
    report_title: string | null
    completion_date: Date | null
    release_date: Date | null
}

export interface User {
    id: string
    full_name: string
}

export interface StatusReportSummary {
    description: string | null
    past_successes: string | null
    next_milestones: string | null
}

export interface StatusReportAttachments {
    attachments:
        | GetReport_releasedOrInReview_report_status_report_attachments[]
        | GetReport_unreleasedOrRejected_report_status_report_attachments[]
}

export type StatusReportStateType = "costs" | "resources" | "schedule" | "scope" | "total"
export type StatusReportStateValue = "in-order" | "major-difficulties" | "minor-difficulties"

export interface StatusReportState {
    id: string
    state: StatusReportStateValue
    type: StatusReportStateType
    comment: string | null
    date: Date
    report_id: string
}

export class StatusReportMilestone {
    constructor(
        public readonly id: string,
        public readonly description: string,
        public readonly status: string,
        public readonly planned_date: Date | null,
        public readonly actual_date: Date | null,
    ) {
    }

    variance(): number | undefined {
        if (this.planned_date && this.actual_date) {
            return differenceInDays(this.actual_date, this.planned_date)
        }
    }
}

export type StatusReportRiskStatus = "Open" | "In Progress" | "Eingetreten" | "Nicht eingetreten"

export class StatusReportRisk {
    constructor(
        public readonly id: string,
        public readonly status: StatusReportRiskStatus,
        public readonly description: string,
        public readonly probability_of_occurrence: string | null,
        public readonly damage_extent: string | null,
        public readonly estimated_cost: number | null,
        public readonly assignee: User | null,
        public readonly next_assessment: Date | null,
        public readonly countermeasures: StatusReportRiskCountermeasure[],
    ) {
    }
}

export class StatusReportRiskCountermeasure {
    constructor(
        public readonly id: string,
        public readonly status: string,
        public readonly description: string,
        public readonly assignee: User | null,
        public readonly due_date: Date | null,
    ) {
    }
}

export interface StatusReportProblem {
    id: string
    name: string
    assignee: User | null
    status: string
    due_date: Date | null
}

export interface StatusReportChangeRequest {
    description: string
    reporter: User | null
    assignee: User | null
    status: string
}

const toDate = (date: string | null): Date | null => {
    if (date) {
        return new Date(date)
    }
    return null
}

export const unreleasedOrRejectedReport = (report: GetReport): GetReport_unreleasedOrRejected_report | undefined => {
    if (report.unreleasedOrRejected_report?.length > 0) {
        return report.unreleasedOrRejected_report[0]
    }
}

const inReviewOrReleasedReport = (report: GetReport): GetReport_releasedOrInReview_report | undefined => {
    if (report.releasedOrInReview_report?.length > 0) {
        return report.releasedOrInReview_report[0]
    }
}

const getReportByState = (report: GetReport): GetReport_unreleasedOrRejected_report | GetReport_releasedOrInReview_report => {
    if (unreleasedOrRejectedReport(report)) {
        return unreleasedOrRejectedReport(report)!
    }
    return inReviewOrReleasedReport(report)!
}

const overview = (report: GetReport): StatusReportOverview => {
    if (inReviewOrReleasedReport(report)) {
        const inReviewOrReleased = inReviewOrReleasedReport(report)!
        const initiative = inReviewOrReleased.initiative

        return {
            project_name: inReviewOrReleased.project_name,
            manager: inReviewOrReleased.project_manager_user,
            deputy_manager_id: initiative?.deputy_manager ?? null,
            sponsor_id: initiative?.sponsor ?? null,
            deputy_sponsor_id: initiative?.deputy_sponsor ?? null,
            project_status: inReviewOrReleased.project_status,
            portfolio: inReviewOrReleased.portfolio,
            state: inReviewOrReleased.state,
            phase: inReviewOrReleased.project_phase,
            planned_start_date: toDate(inReviewOrReleased.planned_start_date),
            planned_end_date: toDate(inReviewOrReleased.planned_end_date),
            planned_costs: inReviewOrReleased.planned_costs,
            actual_start_date: toDate(inReviewOrReleased.actual_start_date),
            actual_end_date: toDate(inReviewOrReleased.actual_end_date),
            actual_costs: inReviewOrReleased.actual_costs,
            report_date: toDate(inReviewOrReleased.report_date)!,
            report_title: inReviewOrReleased.report_title,
            completion_date: latestStateChangeTo("in_review", report),
            release_date: latestStateChangeTo("released", report),
        }
    }

    const unreleased = unreleasedOrRejectedReport(report)!
    const initiative = unreleased.initiative

    return {
        project_name: initiative?.summary!,
        manager: initiative?.manager_user!,
        deputy_manager_id: initiative?.deputy_manager ?? null,
        sponsor_id: initiative?.sponsor ?? null,
        deputy_sponsor_id: initiative?.deputy_sponsor ?? null,
        project_status: initiative?.status!,
        portfolio: initiative?.portfolio?.name!,
        state: unreleased.state,
        phase: initiative?.phase ?? null,
        planned_start_date: toDate(initiative?.planned_start_date ?? null),
        planned_end_date: toDate(initiative?.planned_end_date ?? null),
        planned_costs: report.costs.total_planned,
        actual_start_date: toDate(initiative?.actual_start_date ?? null),
        actual_end_date: toDate(initiative?.actual_end_date ?? null),
        actual_costs: report.costs.total_actual,
        report_date: new Date(unreleased.report_date ?? ""),
        report_title: unreleased.report_title,
        completion_date: new Date(),
        release_date: null,
    }
}

const latestStateChangeTo = (state: string, report: GetReport) => {
    const dates = report.history.filter((history) => history.new_state === state).map((state) => new Date(state.timestamp))
    if (dates.length === 0) return null
    return max(dates)
}

const attachments = (report: GetReport): StatusReportAttachments => {
    return {attachments: getReportByState(report).status_report_attachments}
}

const summary = (report: GetReport): StatusReportSummary => {
    return getReportByState(report) as StatusReportSummary
}

const states = (getReport: GetReport): StatusReportState[] => {
    const report = getReportByState(getReport)
    const currentStates = report.project_states.map((state) => {
        return {
            id: state.id,
            state: state.state as StatusReportStateValue,
            type: state.type as StatusReportStateType,
            comment: (state as GetReport_releasedOrInReview_report_project_states_status_report_status_report).comment,
            date: new Date(state.status_report.report_date),
            report_id: state.status_report_id,
        }
    })

    const oldStates =
        getReport.status_history?.portfolio_project?.status_reports
            .flatMap((oldReport) =>
                oldReport.project_states.map((state) => {
                    return {
                        id: state.id,
                        state: state.state as StatusReportStateValue,
                        type: state.type as StatusReportStateType,
                        comment: null,
                        date: toDate(oldReport.report_date)!,
                        report_id: state.id,
                    }
                }),
            )
            .reverse() || []

    return [...oldStates, ...currentStates]
}

const milestones = (report: GetReport): StatusReportMilestone[] => {
    if (inReviewOrReleasedReport(report)) {
        const milestones = inReviewOrReleasedReport(report)?.milestones ?? []

        return milestones.map((milestone) => {
            return new StatusReportMilestone(
                milestone.id,
                milestone.description,
                milestone.status,
                toDate(milestone.planned_date),
                toDate(milestone.actual_date),
            )
        })
    }

    const toMilestone = (fragment: MilestoneFragment) => new StatusReportMilestone(
        fragment.issue_id,
        fragment.summary,
        fragment.status,
        toDate(fragment.planned_end_date),
        toDate(fragment.actual_end_date),
    )
    const initiative = unreleasedOrRejectedReport(report)?.initiative
    const milestones = initiative?.milestones
        .map((milestone) => toMilestone(milestone)) ?? []

    const childrenMilestones = initiative?.children
        ?.flatMap((child) => child.milestones
            .flatMap((milestone) => toMilestone(milestone))) ?? []

    return [...milestones, ...childrenMilestones]
}

const risks = (report: GetReport): StatusReportRisk[] => {
    if (inReviewOrReleasedReport(report)) {
        const risks = inReviewOrReleasedReport(report)?.risks ?? []

        return risks.map((risk) => {
            return new StatusReportRisk(
                risk.id,
                risk.status as StatusReportRiskStatus,
                risk.description,
                risk.probability_of_occurrence,
                risk.damage_extent,
                risk.estimated_cost,
                risk.assignee_user,
                toDate(risk.next_assessment),
                risk.countermeasures.map((measure) => {
                    return new StatusReportRiskCountermeasure(
                        measure.id,
                        measure.status,
                        measure.description,
                        measure.assignee_user,
                        toDate(measure.due_date),
                    )
                }),
            )
        })
    }

    const toRisk = (fragment: RiskFragment) => new StatusReportRisk(
        fragment.issue_id,
        fragment.status as StatusReportRiskStatus,
        fragment.summary,
        fragment.probability,
        fragment.damage_impact,
        fragment.estimated_costs,
        fragment.assignee_user,
        toDate(fragment.next_assessment),
        fragment.countermeasures.map((measure) => {
            return new StatusReportRiskCountermeasure(
                measure.issue_id,
                measure.status,
                measure.summary,
                measure.assignee_user,
                toDate(measure.due_date),
            )
        }),
    )

    const initiative = unreleasedOrRejectedReport(report)?.initiative
    const risks = initiative?.risks.map((risk) => toRisk(risk)) ?? []

    const childrenRisks = initiative?.children.flatMap((child) => {
        return child.risks.flatMap((risk) => toRisk(risk))
    }) ?? []

    return [...risks, ...childrenRisks]
}

const problems = (report: GetReport): StatusReportProblem[] => {
    if (inReviewOrReleasedReport(report)) {
        const problems = inReviewOrReleasedReport(report)?.problems ?? []
        return problems.map((problem) => {
            return {
                id: problem.id,
                name: problem.name,
                assignee: problem.assignee_user,
                status: problem.status,
                due_date: toDate(problem.due_date),
            }
        })
    }

    const toProblem = (fragment: ProblemFragment): StatusReportProblem => ({
        id: fragment.issue_id,
        name: fragment.summary,
        assignee: fragment.assignee_user,
        status: fragment.status,
        due_date: toDate(fragment.due_date),
    })

    const initiative = unreleasedOrRejectedReport(report)?.initiative
    const problems = initiative?.problems.map((problem) => toProblem(problem)) ?? []
    const childrenProblems = initiative?.children.flatMap((child) => {
        return child.problems.flatMap((problem) => toProblem(problem))
    }) ?? []

    return [...problems, ...childrenProblems]
}

export const changeRequests = (report: GetReport): StatusReportChangeRequest[] => {
    if (inReviewOrReleasedReport(report)) {
        const change_requests = inReviewOrReleasedReport(report)?.change_requests ?? []
        return change_requests.map((changeRequest) => {
            return {
                description: changeRequest.description,
                status: changeRequest.status,
                assignee: changeRequest.assignee_user,
                reporter: changeRequest.reporter_user,
            }
        })
    }

    const toChangeRequest = (fragment: ChangeRequestFragment): StatusReportChangeRequest => ({
        description: fragment.summary,
        status: fragment.status,
        assignee: fragment.assignee_user,
        reporter: fragment.reporter_user,
    })

    const initiative = unreleasedOrRejectedReport(report)?.initiative
    const changeRequests = initiative?.change_requests.map((changeRequest) => toChangeRequest(changeRequest)) ?? []
    const childrenChangeRequests = initiative?.children.flatMap((child) => {
        return child.change_requests.flatMap((changeRequest) => toChangeRequest(changeRequest))
    }) ?? []

    return [...changeRequests, ...childrenChangeRequests]
}

const reportType = (report: GetReport): "program" | "project" => {
    if (inReviewOrReleasedReport(report)) {
        const inReviewOrReleased = inReviewOrReleasedReport(report)!
        return inReviewOrReleased.initiative?.type ? "project" : "program"
    }
    const unreleased = unreleasedOrRejectedReport(report)!
    return unreleased.initiative?.type ? "project" : "program"
}

export const toStatusReportData = (report: GetReport): StatusReportData => {
    const reportOverview = overview(report)
    const reportAttachments = attachments(report)
    const reportSummary = summary(report)
    const reportStates = states(report)
    const reportMilestones = milestones(report)
    const reportRisks = risks(report)
    const reportProblems = problems(report)
    const reportChangeRequests = changeRequests(report)
    const type = reportType(report)

    return new StatusReportData(
        getReportByState(report).id,
        type,
        reportOverview,
        reportAttachments,
        reportSummary,
        reportStates,
        reportMilestones,
        reportRisks,
        reportProblems,
        reportChangeRequests,
    )
}
