import React from "react"
import styles from "./App.module.scss"
//package imports
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import {useTranslation} from "react-i18next"
import {Link} from "react-router-dom"
import useMediaQuery from "@mui/material/useMediaQuery"
//component imports
import {FeaturesToggleContext} from "./App"

interface Props {
    setSidebarToggle?: (value: boolean) => void
}

const Sidebar: React.FC<Props> = ({setSidebarToggle}) => {
    const {t} = useTranslation("translations")
    const isXSScreen = useMediaQuery("(max-width: 600px)")
    const featuresToggle = React.useContext(FeaturesToggleContext)

    return (
        <div className={styles.sidebar}>
            <div className={styles.sidebarLogo}></div>
            {isXSScreen && setSidebarToggle && (
                <div className={styles.mobileSidebarToggle} onClick={() => setSidebarToggle(false)}>
                    <FontAwesomeIcon size="1x" icon={"chevron-left"} />
                </div>
            )}
            <div className={styles.routeLinksList}>
                {/* Activates section header in sidebar: */}
                {/* Project Management links */}
                <div className={`${styles.sidebarHeader} ${styles.iconLinkWrapper}`}>
                    <h4>{t("project-management")}</h4>
                </div>
                <ul>
                    <li>
                        <Link to="/project-management/project-status-report/" className={styles.iconLinkWrapper}>
                            <div className={styles.sidebarLinkIcon}>
                                <FontAwesomeIcon size="1x" icon={["far", "file-chart-pie"] as IconProp} />
                            </div>
                            <p>{t("status-reports")}</p>
                        </Link>
                    </li>

                    {featuresToggle.sharedfiles && (
                        <li>
                            <Link to="/project-management/shared-files" className={styles.iconLinkWrapper}>
                                <div className={styles.sidebarLinkIcon}>
                                    <FontAwesomeIcon size="1x" icon={["far", "share-alt-square"] as IconProp} />{" "}
                                </div>
                                <p>{t("shared-files")}</p>
                            </Link>
                        </li>
                    )}
                </ul>

                {/* Portfolio Analysis links */}

                <ul>
                    {featuresToggle.projectList && (
                        <li>
                            <Link to="/portfolio-analysis/project-list" className={styles.iconLinkWrapper}>
                                <div className={styles.sidebarLinkIcon}>
                                    <FontAwesomeIcon size="1x" icon="list" />
                                </div>
                                <p>{t("project-list")}</p>
                            </Link>
                        </li>
                    )}
                    {featuresToggle.overview && (
                        <li>
                            <Link to="/portfolio-analysis/overview" className={styles.iconLinkWrapper}>
                                <div className={styles.sidebarLinkIcon}>
                                    <FontAwesomeIcon size="1x" icon={["far", "chart-network"] as IconProp} />
                                </div>
                                <p>{t("overview")}</p>
                            </Link>
                        </li>
                    )}

                    {featuresToggle.projectStatusDev && (
                        <li>
                            <Link to="/portfolio-analysis/project-status-development" className={styles.iconLinkWrapper}>
                                <div className={styles.sidebarLinkIcon}>
                                    <FontAwesomeIcon size="1x" icon={["far", "file-chart-line"] as IconProp} />
                                </div>
                                <p>{t("project-status-development")}</p>
                            </Link>
                        </li>
                    )}
                    {featuresToggle.planBudgetActualComparison && (
                        <li>
                            <Link to="/portfolio-analysis/plan-budget-actual-comparison" className={styles.iconLinkWrapper}>
                                <div className={styles.sidebarLinkIcon}>
                                    <FontAwesomeIcon size="1x" icon="chart-bar" />
                                </div>
                                <p>{t("plan-budget-actual-comparison")}</p>
                            </Link>
                        </li>
                    )}
                    {featuresToggle.portfolioReport && (
                        <li>
                            <Link to="/portfolio-analysis/portfolio-report" className={styles.iconLinkWrapper}>
                                <div className={styles.sidebarLinkIcon}>
                                    <FontAwesomeIcon size="1x" icon="chart-bar" />
                                </div>
                                <p>{t("portfolio-report")}</p>
                            </Link>
                        </li>
                    )}
                    {featuresToggle.riskReport && (
                        <li>
                            <Link to="/portfolio-analysis/risk-report" className={styles.iconLinkWrapper}>
                                <div className={styles.sidebarLinkIcon}>
                                    <FontAwesomeIcon size="1x" icon={["far", "burst"] as IconProp} />
                                </div>
                                <p>{t("risk-report")}</p>
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default Sidebar
