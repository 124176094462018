import React from "react"
import {BarDatum, ResponsiveBar} from "@nivo/bar"
import {useTranslation} from "react-i18next"
import styles from "../../ReportStyles.module.scss"
import {format, isEqual} from "date-fns"
import {GraphDialogProps} from "../PortfolioReportMainContent"
import {ProjectStateData} from "./projectStateData"

interface Props {
    data: ProjectStateData
    setTableDialogOpen?: (value: GraphDialogProps) => void
    printVersion?: boolean
}

interface ChartDataTypes {
    reportDate: Date
    minorDifficulties: number
    majorDifficulties: number
    inOrder: number
}

const TotalProjectsByStatus: React.FC<Props> = ({data, setTableDialogOpen, printVersion}) => {
    const {t} = useTranslation("translations")

    const monthlyProjectStates = data.monthlyProjectStates()

    const chartData: ChartDataTypes[] = monthlyProjectStates.map((groupedByDate) => {
        return {
            reportDate: groupedByDate.date,
            minorDifficulties: groupedByDate.states.filter((state) => state.state === "minor-difficulties").length,
            majorDifficulties: groupedByDate.states.filter((state) => state.state === "major-difficulties").length,
            inOrder: groupedByDate.states.filter((state) => state.state === "in-order").length,
        }
    })

    const generateLabel = (chartDataType: any) => {
        if (chartDataType.id === "minorDifficulties") return t("portfolio-report-state-minor-difficulties")
        if (chartDataType.id === "majorDifficulties") return t("portfolio-report-state-major-difficulties")
        if (chartDataType.id === "inOrder") return t("portfolio-report-state-in-order")
        return chartDataType.id
    }

    return (
        <ResponsiveBar
            animate={!printVersion}
            data={chartData as unknown as BarDatum[]}
            keys={["inOrder", "minorDifficulties", "majorDifficulties"]}
            indexBy="reportDate"
            theme={{
                fontSize: 12,
                labels: {
                    text: {
                        fontWeight: 800,
                        fontSize: 14,
                    },
                },
            }}
            margin={{
                top: 30,
                right: 170,
                bottom: 80,
                left: 60,
            }}
            padding={0.3}
            valueScale={{type: "linear"}}
            indexScale={{type: "band", round: true}}
            colors={["#00c851", "#ffbb33", "#ff4444"]}
            borderColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: <tspan style={{fontWeight: 550}}>{t("portfolio-report-project-status-report-month")}</tspan>,
                legendPosition: "middle",
                legendOffset: 45,
                format: (date) => <tspan style={{fontSize: "1.1em"}}>{format(date, "MM.yyyy")}</tspan>,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: <tspan style={{fontWeight: 550}}>{t("portfolio-report-project-status-number-of-projects")}</tspan>,
                legendPosition: "middle",
                legendOffset: -40,
                format: (e) => Math.floor(e) === e && e,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
            }}
            legendLabel={(x) => generateLabel(x)}
            legends={[
                {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 110,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    itemTextColor: "#212427",
                },
            ]}
            role="application"
            ariaLabel="Total Projects by Status (Last 12 Months)"
            tooltip={(item) => {
                const label = generateLabel(item)
                return (
                    <div className={styles.graphToolTip}>
                        <div className={styles.colorBox} style={{backgroundColor: `${item.color}`}}></div>
                        <p>
                            {label}
                            {` - `}
                            <b>{item.value}</b>
                        </p>
                    </div>
                )
            }}
            onClick={(node) => {
                const filteredByClickedDate = monthlyProjectStates
                    .filter((data) => {
                        return isEqual(data.date, new Date(node.data.reportDate))
                    })
                    .flatMap((data) => data.states)
                setTableDialogOpen &&
                    setTableDialogOpen({
                        graphType: "status-total",
                        filterValue: "",
                        totalStatusData: filteredByClickedDate,
                    })
            }}
        />
    )
}

export default TotalProjectsByStatus
