import {IconProp} from "@fortawesome/fontawesome-svg-core"
import {TFunction} from "react-i18next"

export const ProjektsAndProgramsDropdown = (
    projecktsAndProgramsOptions: {
        name: string
        id: string
    }[]
) => {
    return {
        title: "project_or_program",
        options: projecktsAndProgramsOptions,
        type: "project_or_program",
        icon: ["far", "rectangle-history"] as IconProp,
    }
}

export const RiskLevelDropdown = (t: TFunction<"translations", undefined>) => {
    return {
        title: "damage_impact",
        options: [
            {name: `${t("all")}`, id: ""},
            {name: t("damage-impact-tief"), id: "Tief"},
            {name: t("damage-impact-mittel"), id: "Mittel"},
            {name: t("damage-impact-hoch"), id: "Hoch"},
        ],
        icon: ["far", "arrow-up-right-dots"] as IconProp,

        type: "damage_impact",
    }
}

export const RiskProbabilityDropdown = (t: TFunction<"translations", undefined>) => {
    return {
        title: "risk_probability",
        options: [
            {name: `${t("all")}`, id: ""},
            {name: t("risk-probability-niedrig"), id: "Niedrig"},
            {name: t("risk-probability-mittel"), id: "Mittel"},
            {name: t("risk-probability-hoch"), id: "Hoch"},
            {name: t("risk-probability-absolut"), id: "Absolut"},
        ],
        icon: ["far", "arrow-up-right-dots"] as IconProp,

        type: "probability",
    }
}

export const RisksPriorityDropdown = (t: TFunction<"translations", undefined>) => {
    return {
        title: "risk_priority",
        options: [
            {name: `${t("all")}`, id: ""},
            {name: t("risk-priority-tief"), id: "Tief"},
            {name: t("risk-priority-mittel"), id: "Mittel"},
            {name: t("risk-priority-hoch"), id: "Hoch"},
        ],
        icon: "arrow-up-wide-short",
        type: "priority",
    }
}
