import {GetRisksByInitiative_initiatives, GetRisksByInitiative_initiatives_risks} from "./types/GetRisksByInitiative"

export type RiskStatus = "Open" | "In Progress" | "Eingetreten" | "Nicht eingetreten"

export class RiskReportData {
    constructor(public readonly initiatives: GetRisksByInitiative_initiatives[]) {
    }

    public numberOfProjects(): number {
        return this.projects().length ?? 0
    }

    public numberOfPrograms(): number {
        return this.programs().length ?? 0
    }

    public projects(): GetRisksByInitiative_initiatives[] {
        return this.initiatives.filter(initiative => initiative.initiative_type === "project")
    }

    public programs(): GetRisksByInitiative_initiatives[] {
        return this.initiatives.filter(initiative => initiative.initiative_type === "program")
    }

    public risks(): GetRisksByInitiative_initiatives_risks[] {
        return this.initiatives.flatMap((initiative) => initiative.risks)
    }

    public getRiskByStatus(status: RiskStatus[]): GetRisksByInitiative_initiatives_risks[] {
        return this.risks().filter((risk) => status.indexOf(risk.status as RiskStatus) >= 0)
    }
}
