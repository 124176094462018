export const getDamageImpactLevel = (level: string) => {
    if (level === "Hoch") return "4"
    if (level === "Wesentlich") return "3"
    if (level === "Mittel") return "2"
    if (level === "Tief") return "1"
    return 0
}

export const getProbabilityLevel = (level: string) => {
    if (level === "Absolut") return "4"
    if (level === "Hoch") return "3"
    if (level === "Mittel") return "2"
    if (level === "Niedrig") return "1"
}
