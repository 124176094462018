import React from "react"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Table from "@mui/material/Table"
import Paper from "@mui/material/Paper"
import {useTranslation} from "react-i18next"
import {
    FilterPortfolios_initiatives,
} from "./types/FilterPortfolios"
import {GraphDialogProps} from "./PortfolioReportMainContent"
import {capitalizeFirstLetter, formatStringForTranslation} from "../../../utils/strings"
import {ProjectState, ProjectStateData} from "./Charts/projectStateData"
import {formatStringDateFromDate, formatStringDateFromStringOrElse} from "../../../utils/dates"
import {openExternalProject} from "../../../utils/link"
import {determineDuration} from "../utils/duration"

interface Props {
    data: (FilterPortfolios_initiatives)[]
    stateData?:
        | {
              projectId: string
              data: ProjectStateData
          }[]
        | null
    dialogData?: GraphDialogProps
    totalStatusData?: ProjectState[]
}

const PortfolioReportProjectListTable: React.FC<Props> = ({data, stateData, dialogData, totalStatusData}) => {
    const {t} = useTranslation("translations")
    const graphType = dialogData?.graphType
    const columns = graphType ? ["summary", graphType, "duration", "status"] : ["summary", "duration", "status"]
    const stateColumns = ["summary", graphType, "report-date", "project-status"]

    const durationTranslation = (project: FilterPortfolios_initiatives) => {
        return `${formatStringDateFromStringOrElse(project["actual_start_date"], "?")} - ${formatStringDateFromStringOrElse(
            project["actual_end_date"],
            "?"
        )}`
    }

    const otherFieldsTranslation = (column: string, value: string) => {
        value = formatStringForTranslation(value)
        return t(`${column}-${value}`)
    }

    const determineTranslation = (column: string, project: FilterPortfolios_initiatives) => {
        let value = column === graphType ? (project[graphType as keyof FilterPortfolios_initiatives] as string) : project.status
        if (!value) return "-"
        if (column === graphType) {
            return otherFieldsTranslation(column, value)
        }
        if (column === "duration") {
            return durationTranslation(project)
        } else return project[column as keyof FilterPortfolios_initiatives]
    }

    const columnHeaders = stateData || totalStatusData ? stateColumns : columns

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="project list table" size="small">
                <TableHead>
                    <TableRow style={{background: "rgb(238 238 238)"}}>
                        {columnHeaders.map((header, index) => {
                            return (
                                <TableCell component="th" align={index !== 0 ? "right" : "left"} scope="row" key={index}>
                                    {t(`${header}`)}
                                </TableCell>
                            )
                        })}
                        {}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data &&
                        data.map((project, index) => {
                            return (
                                <TableRow
                                    onClick={() => openExternalProject(project.overview_url)}
                                    sx={{
                                        "&:last-child td, &:last-child th": {border: 0},
                                        cursor: "pointer",
                                        transition: "all 300ms",
                                        "&.MuiTableRow-root:hover": {
                                            backgroundColor: "#f8f8f8",
                                        },
                                    }}
                                    key={index}
                                >
                                    {columns.map((column, index) => {
                                        return (
                                            <TableCell align={index !== 0 ? "right" : "left"} scope="row" key={index}>
                                                {column === "duration" ? determineDuration(project) : determineTranslation(column, project)}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}

                    {stateData &&
                        stateData.map((project, index) => {
                            const stateFromLatestReport = project.data.states[0]
                            return (
                                <TableRow
                                    onClick={() => openExternalProject(stateFromLatestReport.url)}
                                    sx={{
                                        "&:last-child td, &:last-child th": {border: 0},
                                        cursor: "pointer",
                                        transition: "all 300ms",
                                        "&.MuiTableRow-root:hover": {
                                            backgroundColor: "#f8f8f8",
                                        },
                                    }}
                                    key={index}
                                >
                                    <TableCell align="left" scope="row">
                                        {stateFromLatestReport.projectName}
                                    </TableCell>
                                    <TableCell align="right" scope="row">
                                        {`${capitalizeFirstLetter(stateFromLatestReport.type)} - ${t(`${stateFromLatestReport.state}`)}`}
                                    </TableCell>
                                    <TableCell align="right" scope="row">
                                        {formatStringDateFromDate(stateFromLatestReport.reportDate)}
                                    </TableCell>
                                    <TableCell align="right" scope="row">
                                        {stateFromLatestReport.projectState}
                                    </TableCell>
                                </TableRow>
                            )
                        })}

                    {totalStatusData &&
                        totalStatusData.map((projectState, index) => {
                            return (
                                <TableRow
                                    onClick={() => openExternalProject(projectState.url)}
                                    sx={{
                                        "&:last-child td, &:last-child th": {border: 0},
                                        cursor: "pointer",
                                        transition: "all 300ms",
                                        "&.MuiTableRow-root:hover": {
                                            backgroundColor: "#f8f8f8",
                                        },
                                    }}
                                    key={index}
                                >
                                    <TableCell align="left" scope="row">
                                        {projectState.projectName}
                                    </TableCell>
                                    <TableCell align="right" scope="row">
                                        {t(`${projectState.state}`)}
                                    </TableCell>
                                    <TableCell align="right" scope="row">
                                        {formatStringDateFromDate(projectState.reportDate)}
                                    </TableCell>
                                    <TableCell align="right" scope="row">
                                        {projectState.projectState}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default PortfolioReportProjectListTable
